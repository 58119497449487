import { Button } from "@chakra-ui/react"
import React, { useState } from "react"
import { useFirebase } from "../../components/auth/FirebaseContext"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { noSsr } from "../../components/NoSsrContext"

const FirebaseComponent = noSsr(() => {
  const firebase = useFirebase()
  return (
    <>
      <b>Consuming Firebase</b>
      {firebase?.name}
    </>
  )
})

export default function OnDemandFirebase() {
  const [show, setShow] = useState(false)
  return (
    <DebugLayout title="OnDemandFirebase">
      <Button onClick={() => setShow(s => !s)}>Toggle Show</Button>
      {show && <FirebaseComponent />}
    </DebugLayout>
  )
}
